import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Header from "../../components/header"
import Intro from "../../components/intro"
import Apresentacao from "../../components/apresentacao"
import Media from "../../components/media.js"
import Comparor from "../../components/comparor"
import FAQ from "../../components/faq"
import Footer from "../../components/footer"

import HeaderMobile from "../../components/headerMobile"
import ApresentacaoMobile from "../../components/apresentacaoMobile"
import FooterMobile from "../../components/footerMobile"

const OclusaoPage = ({data}) => (
  <Layout>
    <SEO title="Oclusão e Disfunção da ATM" />
    {useBreakpoint().mobile ? (
      <>
        <HeaderMobile header={data.globalJson.header}/>
        <Intro data={data.oclusaoJson.intro} blueBlack={true}/>
        <ApresentacaoMobile data={data.oclusaoJson.introTxt}/>
        <Media data={data.oclusaoJson.media}/>
        <Comparor data={data.oclusaoJson.clinicCases}/>
        <FAQ data={data.oclusaoJson.faq}/>
        <FooterMobile data={data.globalJson.footer} />
      </>
      ) : (
      <>
        <Header header={data.globalJson.header}/>
        <Intro data={data.oclusaoJson.intro} blueBlack={true}/>
        <Apresentacao data={data.oclusaoJson.introTxt}/>
        <Media data={data.oclusaoJson.media}/>
        <Comparor data={data.oclusaoJson.clinicCases}/>
        <FAQ data={data.oclusaoJson.faq}/>
        <Footer data={data.globalJson.footer} />
      </>)
    }
  </Layout>
)

export default OclusaoPage

export const Json = graphql`
  query oclusao {
    globalJson{
      header{
        menu{
          title
          link
        }
        menuselect{
          publicURL
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
      testemunhos{
        title
        subtitle
        quotes{
          text
          author
        }
      }
      footer{
        logo{
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        calendarImg {
          publicURL
        }
        calendarTxt
        calendarLink
        schedule {
          days
          hours
        }
        phone {
          ind
          number
        }
        address
        links {
          title
          link
        }
        map {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        bottomTxt
        social
        {
          face
          insta
          tube
        }
      }
    }
    oclusaoJson{
      intro{
        image{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
        area
      }
      introTxt{
        title
        subtitle
        text
      }
      media{
        title
        subtitle
        text
        media{
          childImageSharp {
            fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
      clinicCases{
        title
        subtitle
        content{
          before{
            publicURL
          }
          after{
            publicURL
          }
          title
          desc
        }
        stories{
          title
          desc
          imgs{
            childImageSharp {
              fluid(quality: 70, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      faq{
        title
        subtitle
        text
        questions{
          q
          a
        }
      }
    }
  }
`